<template>
  <div>
    <page-title title="Chat Us" :breadcrumbs-items="breadcrumbsItems"></page-title>
    
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-card target="_blank" :href="'https://wa.me/' + settings.contact_wa1_number">
          <div class="text-center py-6">
            <v-icon class="display-4">mdi-whatsapp</v-icon>
          </div>
          <v-card-title><h2 class="display-1" style="text-align:center !important;width: 100%">{{ settings.contact_wa1_name }}</h2></v-card-title>
          <v-card-subtitle><p class="text-center" style="color: #aaa;">{{ settings.contact_wa1_number }}</p></v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-card target="_blank" :href="'https://wa.me/' + settings.contact_wa2_number">
          <div class="text-center py-6">
            <v-icon class="display-4">mdi-whatsapp</v-icon>
          </div>
          <v-card-title><h2 class="display-1 text-center" style="text-align:center !important;width: 100%">{{ settings.contact_wa2_name }}</h2></v-card-title>
          <v-card-subtitle><p class="text-center" style="color: #aaa;">{{ settings.contact_wa2_number }}</p></v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-card target="_blank" :href="'https://wa.me/' + settings.contact_wa3_number">
          <div class="text-center py-6">
            <v-icon class="display-4">mdi-whatsapp</v-icon>
          </div>
          <v-card-title><h2 class="display-1" style="text-align:center !important;width: 100%">{{ settings.contact_wa3_name }}</h2></v-card-title>
          <v-card-subtitle><p class="text-center" style="color: #aaa;">{{ settings.contact_wa3_number }}</p></v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>


    <!-- <v-row>
      <v-col md="4">
        <v-card :loading="loading">
          <v-card-title>Contact</v-card-title>
          <v-list two-line>
            <v-list-item target="_blank" :href="'mailto:' + settings.contact_company_email">
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>{{ settings.contact_company_email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item target="_blank" :href="'tel:' + settings.contact_company_telp">
              <v-list-item-icon>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Phone</v-list-item-title>
                <v-list-item-subtitle>{{ settings.contact_company_telp }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item target="_blank" :href="'fax:' + settings.contact_company_fax">
              <v-list-item-icon>
                <v-icon>mdi-fax</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Fax</v-list-item-title>
                <v-list-item-subtitle>{{ settings.contact_company_fax }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item target="_blank" :href="'https://wa.me/' + settings.contact_wa1_number">
              <v-list-item-icon>
                <v-icon>mdi-whatsapp</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ settings.contact_wa1_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ settings.contact_wa1_number }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item target="_blank" :href="'https://wa.me/' + settings.contact_wa2_number">
              <v-list-item-icon>
                <v-icon>mdi-whatsapp</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ settings.contact_wa2_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ settings.contact_wa2_number }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item target="_blank" :href="'https://wa.me/' + settings.contact_wa3_number">
              <v-list-item-icon>
                <v-icon>mdi-whatsapp</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ settings.contact_wa3_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ settings.contact_wa3_number }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    settings: {
      contact_wa1_name: '',
      contact_wa2_name: '',
      contact_wa3_name: '',
      contact_wa1_number: '',
      contact_wa2_number: '',
      contact_wa3_number: '',
    },
    breadcrumbsItems: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '#',
      },
      {
        text: 'Chat with Us',
        disabled: true,
        href: '#',
      },
    ],
  }),
  methods:{
    async getData() {
      this.AxiosStorageRemove("GET", "contact/data");
      this.loading = true
      await this.$axios
        .get("contact/data", { cacheConfig: true, })
        .then((res) => {
          if (res.status) {
            var resData = res.data;
            if (resData.status == "success") {
              this.settings = resData.data;
            }
          } else {
            this.settings = {};
          }
          this.loading = false
        })
        .catch((error) => {
          if (error.response) {
            this.$awn.alert("Internal Server Error");
          }
          this.settings = {};
          this.loading = false
        });
    },
  },
  mounted(){
    this.requiredLogin();
    this.setTitle('Chat Us')
    this.getData();
  },
}
</script>